<template>
  <v-row no-gutters>
    <v-col v-if="icon" cols="1" style="min-width: 70px" class="d-flex justify-center pt-2">
      <router-link :to="computedTo">
        <v-icon :color="backColor" large>{{ icon }}</v-icon>
      </router-link>
    </v-col>
    <v-col class="d-flex-row align-center pt-2">
      <div class="text-subtitle-1 font-weight-medium">
        <router-link :to="computedTo">{{ title }}</router-link>
      </div>
      <div v-if="hasDefaultSlot">
        <slot></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['clientId', 'user']),
    hasDefaultSlot: function () {
      return !!this.$slots.default;
    },
    computedTo: function () {
      return { name: this.to || 'homepage', params: { clientId: this.clientId }};
    },
  },
  props: ['icon', 'title', 'to'],
};
</script>
